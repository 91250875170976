import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {getCMSSettings, getCMSSlot, getCMSConfig} from "tools/cmsBlockSettings";
import Image from 'next/image';
import Link from "next/link";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import theme from "../../../theme";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			marginLeft: '0 !important',
			marginRight: '0 !important'
		}
	},
	link: {
		position: 'relative',
		background: theme.palette.primary.color,
		display: 'block',
		width: '100%'
	},
	video: {
		//position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		border: 'none'
	},
	imageWrapper: {
		[theme.breakpoints.down('xs')]: {
			'&:not(.fixedSize)': {
				marginLeft: -15,
				marginRight: -15,
			}
		},
		'&.responsive': {
			flexGrow: 1
		},
		'.wedding-image-overlap &': {
			marginTop: -40,
			[theme.breakpoints.down('xs')]: {
				marginTop: -20
			}
		}
	}
}), {name: 'Image'});

export default function SWMedia({block, slots, category}) {
	const router = useRouter();
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const image = getCMSSlot('image', slots);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	let aspectRatio = ((9 / 16) * 100) + '%';
	if (isMobile) {
		aspectRatio = ((5 / 4) * 100) + '%';
	}

	function getBannerLink(slot) {
		return slot.data ? slot.data.url : false;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function getMedia() {
		let media = image.data ? image.data.media : false;
		if (media && media.metaData !== null) {
			let imageObject = {
				src: media.url,
				alt: media.metaData.alt || category.metaTitle || 'Newport image'
			};

			if(getCMSConfig('displayMode', image.config) !== 'standard') {
				imageObject.layout = 'intrinsic';
				imageObject.width = media.metaData.width;
				imageObject.height = media.metaData.height;

				if(image.config.height.value) {
					imageObject.style = {
						height: image.config.height.value,
						width: (media.metaData.width / media.metaData.height) * parseInt(image.config.height.value) + 'px'
					};
				}
			} else {
				imageObject.layout = 'responsive';
				imageObject.width = media.metaData.width;
				imageObject.height = media.metaData.height;
			}

			return <div className={[classes.imageWrapper, imageObject.layout, image.config.height.value ? 'fixedSize' : ''].join(' ')} style={imageObject.style}><Image {...imageObject} className={[classes.image, 'image'].join(' ')} unoptimized/></div>;
		} else if (media) {
			console.log('media', media)
			return <div style={{
				position: 'relative',
				width: '100%',
				height: 'auto'
			}}>
				<video src={media.url} className={classes.video} controls autoPlay="autoplay" playsInline={true} loop muted/>
			</div>;
		}
	}

	return (
		<div className={[classes.wrapper, CMSSettings.class].join(' ')} style={{
			...CMSSettings.styles,
			marginLeft: block.marginLeft ? block.marginLeft : 0,
			marginRight: block.marginRight ? block.marginRight : 0
		}}>
			{getBannerLink(image) ?
				<Link href={urlNormalize(getBannerLink(image))}>
					<a className={classes.link}>
						{getMedia()}
					</a>
				</Link> : getMedia()}
		</div>
	);
}
